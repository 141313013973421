.fan {
    position: relative;
    width: 135px;
    height: 135px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #ccc;
    overflow: hidden;
  }
  
  .blade {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 80px;
    margin: -40px 0 0 -2.5px;
    border-radius: 2.5px;
    background-color: #333;
    transition: transform 0.5s ease;
  }
  
  .blade1 {
    transform: rotateZ(0deg) translateX(-35px) rotateZ(0deg);
  }
  
  .blade2 {
    transform: rotateZ(120deg) translateX(-35px) rotateZ(0deg);
  }
  
  .blade3 {
    transform: rotateZ(240deg) translateX(-35px) rotateZ(0deg);
  }
  
  @keyframes rotate {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
  