.action__container {
  div {
    position: relative;
    border: 1px solid #ccc;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    p {
      font-weight: bold;
    }
  }
  ul {
    background: #e0e1e5;
    bottom: 12px;
    position: absolute;
    display: none;
    top: 100%;
    margin: 0 20px 0 0;
    padding: 0;
    width: 100px;
    border-radius: 5px;
    z-index: 1000;
    li {
      list-style: none;
      padding: 5px 10px;
      text-align: center;
      background: #e0e1e5;
      &:hover {
        color: white;
        background: rgba(0, 140, 255, 0.938);
        border-radius: 5px;
      }
    }
  }
  &:hover ul {
    display: block;
    margin: 0;
  }
  p {
    color: red;
  }
}
