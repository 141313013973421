@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.image-shadow {
  box-shadow: 0 0 2px 3px #f5f5f5;
}
.image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.disease-form {
  // border: 1px solid #ccc;
  // padding: 10px 15px;
  // margin: 10px 0;
}

.disease-form-end {
  text-align: end;
}

.nid-check {
  padding: 0 !important;
}

.profile-info {
  @media (max-width: 750px) {
    margin-top: 3px;
  }
}

.personal-info .bottom-border {
  border-bottom: 2px solid #eff1f5;
  margin: 10px auto;
}

.personal-info .bottom-border div {
  padding: 0px;
}

.profile__card .nav-tabs {
  border: 0px;
}

.ltr-support .profile__data {
  padding-left: 5px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  @include themify($themes) {
    background-color: themed("colorBackground");
  }
  min-height: 300px;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  @include themify($themes) {
    background-color: themed("colorBackground");
  }
}

.content-left {
  text-align: left !important;
}

.profile__information {
  padding: 20px 0px !important;
}

.list li {
  margin-bottom: 0px;
}

// Accordion start
.MuiButtonBase-root {
  display: flex !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  padding: 0 20px;
}

.profile__card .tab-content {
  padding: 0 0px 40px 0px !important;
}
.bg-accordion {
  @include themify($themes) {
    background-color: themed("colorBackground");
  }
}
// Accordion close

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .profile__card .tab-content {
    padding: 0 0px 40px 0px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .profile__card .tab-content {
    padding: 0 30px 40px 30px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}

//crop details info tabs tabs__wrap
.min-width-initial .tabs .tabs__wrap {
  min-width: initial !important;
}
///loader css
.loader-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// pesticide slider
.slick-slider {
  height: 400px !important;
}
.slick-slider .slick-slide img {
  height: 400px !important;
  object-fit: none;
}

// request from text area style
.request-form textarea {
  min-height: 190px;
}
.account_by {
  font-weight: normal;
}

// multi select custom height
.react-select {
  height: auto;
}
//participant table header
.participant-table {
  border: 1px solid $color-ipage !important;
  tbody {
    tr {
      td {
        border: 1px solid $color-ipage;
        padding: 8px;
      }
    }
    tr:nth-child(even) {
      background-color: #ecfdd5;
    }
  }
}
.participates-table-header {
  th {
    background: $color-ipage !important;
    color: #fff !important;
    border: 1px solid $color-ipage !important;
    padding: 10px 5px !important;
  }
}
.custom_calendar {
  div {
    input {
      padding: 10px;
      font-size: 14px;
    }
  }
}

// post status btn color

// pending btn
.btn-post-pending {
  background-color: #d9534f !important;
  color: white !important;
}
.btn-post-pending:hover {
  background-color: #ca5551 !important;
}
.btn-post-pending:before {
  background-color: #a05353 !important;
}

// working btn
.btn-post-working {
  background-color: #0275d8 !important;
  color: white !important;
}
.btn-post-working:hover {
  background-color: #0275d8 !important;
}
.btn-post-working:before {
  background-color: #3899ee !important;
}

// resolved btn
.btn-post-resolved {
  background-color: #5cb85c !important;
  color: white !important;
}
.btn-post-resolved:hover {
  background-color: #5cb85c !important;
}
.btn-post-resolved:before {
  background-color: #2c962c !important;
}

// pending post list
.published-fs-8 {
  font-size: 10px;
}

// pending post active
.pending-post-active {
  background-color: #e0dede;
}

// comment border
.comment-border-xs {
  border: 1px solid #f1f1f1;
  background-color: #f7f7f7 !important;
  border-radius: 7px;
}
.comment-date {
  font-size: 10px;
}
// .comment-author{
//   font-size: 10px;
// }

// preview image remove button
.img-preview {
  width: 100px;
  position: relative;
}
.img-preview-img {
}
.img-preview-btn,
.img-preview-blur {
  position: absolute;
}
.img-preview-blur {
  background-color: #0006;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.img-preview-btn {
  color: red;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  font-weight: bolder;
}

// address css for hub
.address {
  p {
    color: #6c757d;
    font-size: 14px;
    padding-right: 5px;
  }
  h4 {
    color: #999999;
  }
}

// request submission chat
.chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
}

.chat-history ul {
  padding: 0;
}

.chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat-history .message-data {
  margin-bottom: 15px;
}

.chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  min-width: 80%;
}

.chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .my-message {
  background: #efefef;
}

.chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

// request submission comment box

// swal2 customc color
.swal2-confirm {
  background-color: #5cb85c !important;
  margin-left: 10px;
}

// request submission image slider
.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev,
.react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container {
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}

.react-select__manu {
  background-color: #2c962c !important;
}

.password {
  .form__form-group-button {
    position: absolute;
    top: 25px;
    right: 0;
  }
}
.joiningCalender .MuiFormControl-root {
  width: 100%;
}

// bell icon with notification
.bell-icon-notification {
  text-decoration: none;
  padding: 5px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  font-size: 20px;
}

.bell-icon-notification .badge {
  position: absolute;
  top: -8px;
  right: -4px;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: white;
  color: #2c3644;
}

// leave calender css disable
.leave-calender .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
}

// leave multiple image dropzone custom css
.leave-dropzone .dropzone__input,
.leave-dropzone .dropzone.dropzone--multiple {
  min-height: 160px !important;
}

// employee or user list css
.employee-list__img-wrap img {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 2px;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
}

// employee profile details in modal
.employee-profile {
  background: #fff;
}
.employee-profile .profile-img {
  // text-align: center;
}
.employee-profile .profile-img img {
  // width: 70%;
  // height: 100%;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: 1px solid #ddd;
  padding: 2px;
}
.employee-profile .profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.employee-profile .profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.employee-profile .profile-head h5 {
  color: #333;
}
.employee-profile .profile-head h6 {
  color: #0062cc;
}
.employee-profile .profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.employee-profile .proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.employee-profile .proile-rating span {
  color: #495057;
  font-size: 12px;
  font-weight: 600;
}
.employee-profile .profile-head .nav-tabs {
  margin-bottom: 5%;
}
.employee-profile .profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.employee-profile .profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.employee-profile .profile-work {
  // padding: 14%;
  // margin-top: -15%;
}
.employee-profile .profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.employee-profile .profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.employee-profile .profile-work ul {
  list-style: none;
}
.employee-profile .profile-tab label {
  font-weight: 600;
}
.employee-profile .profile-tab p {
  font-weight: 600;
}

.react-select__control--is-disabled {
  background-color: #f2f4f7 !important;
}

// request submission chat
.chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
}

.chat-history ul {
  padding: 0;
}

.chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat-history .message-data {
  margin-bottom: 15px;
}

.chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  min-width: 80%;
}

.chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .my-message {
  background: #efefef;
}

.chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

// request submission comment box

// swal2 customc color
.swal2-confirm {
  background-color: #5cb85c !important;
  margin-left: 10px;
}

// request submission image slider
.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev,
.react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}
.password {
  .form__form-group-button {
    position: absolute;
    top: 25px;
    right: 0;
  }
}

.search-class {
  border: 1px solid #dfdfdf;
  padding: 10px;
  width: 100%;
  color: black;
}
.search-class:active,
.search-class:focus {
  outline: none;
  border-color: #ffc001;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container {
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}

// comment image upload button

.comment-image {
  margin-top: 5px;

  // width:100%;
  // height:100%;
  // display:flex;
  // align-items:center;
  // justify-content:center;

  .file-upload {
    // height:200px;
    // width:200px;
    // border-radius: 100px;
    position: relative;

    // display:flex;
    // justify-content:center;
    // align-items: center;

    // border:4px solid #FFFFFF;
    // overflow:hidden;
    // background-image: linear-gradient(to bottom, #2590EB 50%, #FFFFFF 50%);
    // background-size: 100% 200%;
    // transition: all 1s;
    // color: #FFFFFF;
    // font-size:100px;

    input[type="file"] {
      // height:200px;
      // width:200px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      // background-position: 0 -100%;

      color: #ffc001;
    }
  }
}

// not attendance bg color
.bg-not-attendance {
  background-color: #ff00000d !important;
}

// profile dark
.theme-dark .profile {
}
.theme-dark .profile th,
.theme-dark .profile .profile-edit-btn,
.theme-dark .profile td {
  color: #999999;
}
.theme-dark .profile .profile-card-border {
  border: 1px solid #999999;
}

.theme-dark .inbox__email-btn {
  background-color: rgb(45, 52, 70);
  border-color: #98b5cf !important;
  border: 1px solid;
  transition: all 0.4s;
}
.theme-dark .inbox__email-btn:hover {
  background-color: rgb(45, 52, 70) !important;
  border-color: #191d27 !important;
}
.theme-dark .inbox__email-btn .lnr {
  color: #999999;
}
.theme-dark .table-filter {
  background-color: transparent !important;
  color: #fff;
}
.theme-dark .table-filter option {
  background-color: #232329 !important;
  color: #fff;
}

// upload image preview
.upload-img-container {
}
.upload-img-container .upload-img {
  height: 100px;
  width: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.upload-img-container .upload-delete-btn {
  top: -8px;
  left: 90px;
  cursor: pointer;
}
.upload-img-container .prev-download-btn {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// last child of tr
.table-tr-last-child > tr:last-child {
  height: 200px;
}
// dark mode btn
.theme-dark .toggle-btn__input:checked ~ .toggle-btn__input-label:after {
  box-shadow: 0px 0px 5px 1px #fff;
}
.theme-dark .toggle-btn__input-label {
  background-color: #515151 !important;
}

// active nav tab dark mode
.theme-dark .nav-tabs .active.nav-link {
  color: #d7d4d7 !important;
  background-color: #2a2a31 !important;
}
.theme-dark .nav-tabs .nav-link {
  color: #d7d4d7 !important;
}
#ReactSimpleImageViewer {
  z-index: 10000 !important;
}

// material ui date label z-index to 0
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

// for react date picker custom min height
.react-datepicker-wrapper .react-datepicker__input-container input {
  min-height: 38px !important;
  border: 1px solid #dfdfdf;
  color: #262626;
}
.ql-editor {
  min-height: 300px;
}
.modal-content {
  border-radius: 15px;
}
